<template>
  <CriaEnt />
</template>

<script>
import CriaEnt from "@/components/entidades/CriaEntidade.vue";

export default {
  components: {
    CriaEnt
  }
};
</script>
